#itemContainer {
  background-color: #9b9b9b;
  border-radius: 10%;
}
#itemContainer:hover {
  background-color: rgb(15, 185, 163);
}
#itemContainerSelected {
  background-color: rgb(15, 185, 163);
  border-radius: 10%;
}
