@font-face {
  font-family: "Frederik-Light";
  src: local("Frederik-Light"),
    url(./assets/fonts/Frederik-Light.woff) format("woff");
}

@font-face {
  font-family: "Frederik-Regular";
  src: local("Frederik-Regular"),
    url(./assets/fonts/Frederik-Regular.woff) format("woff");
}

@font-face {
  font-family: "Frederik-Medium";
  src: local("Frederik-Medium"),
    url(./assets/fonts/Frederik-Medium.woff) format("woff");
}

@font-face {
  font-family: "Frederik-DemiBold";
  src: local("Frederik-DemiBold"),
    url(./assets/fonts/Frederik-DemiBold.woff) format("woff");
}

@font-face {
  font-family: "Frederik-Bold";
  src: local("Frederik-Bold"),
    url(./assets/fonts/Frederik-Bold.woff) format("woff");
}
